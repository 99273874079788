@tailwind base;
@tailwind components;
@tailwind utilities;
@import '../../../../libs/angular/angular-ui/assets/modal-styles';
@import '../../../../libs/angular/angular-editor/src/lib/editor-styles';

nav.fixed {
  position: initial;
}

/* You can add global styles to this file, and also import other style files */


nav.fixed {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: flex-start !important;
}

table {
  border-spacing: 0;
  width: 100%;
}

th {
  @apply border px-3 py-3 bg-slate-100;
}

td {
  @apply border px-3 py-3;
}

sv-form-group {
  display: flex;
  flex-direction: column;

  input, textarea, nb-select {
    max-width: 100% !important;
  }
}

small.error, small.error-fg {
  font-weight: lighter;
  color: red;
}


.sv-required label::after {
  content: '*';
  color: red;
}

.step-content {
  flex: 1;
}

p {
  min-height: 1rem;
}

.cdk-drag-preview {
  z-index: 1001 !important; /* Adjust this value based on your needs */
}

.cdk-overlay-container {
  z-index: 2000 !important;
}


.nb-theme-default nb-select.size-medium:not(.full-width) {
  max-width: 100% !important;
}
