@tailwind base;
@tailwind components;
@tailwind utilities;

.bold {
  font-weight: bold !important;
}

.font-medium {
  font-weight: 500 !important;
}

.blue {
  color: blue;
}

.yellow {
  color: orange;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

ol.roman {
  list-style-type: upper-roman;
  padding-left: 62px;
}

ol.numeric {
  list-style-type: initial;
}

ul.arrow {
  position: relative;
  list-style: none;

  li::before {
    content: '▶';
    position: absolute;
    left: 20px;
    font-size: 0.8rem;
  }
}

