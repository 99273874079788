.normal-dialog nb-dialog-container {
  overflow: hidden;
  max-height: 95%;
  width: 500px;
}

.normal-dialog nb-card-body {
  max-height: 95%;
  width: 500px;
  overflow-y: auto;
}

.medium-dialog nb-dialog-container {
  max-height: 95%;
  width: 850px;
  overflow: hidden;
}

.medium-dialog nb-card-body {
  max-height: 95%;
  width: 850px;
  overflow-y: auto;
}

.large-dialog nb-dialog-container {
  width: clamp(300px, 1200px, 95vw);
  overflow: hidden;
}

.media-library nb-dialog-container {
  width: clamp(300px, 1680px, 95vw);
  overflow: hidden;
}

.large-dialog nb-card-body {
  max-height: 800px;
  overflow-y: auto;
}
